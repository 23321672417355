const loadBlock = ( initBlock, blockName, blockSelector ) => {
	if ( window.acf ) {
		window.acf.addAction( 'ready', function() {
			if ( window.acf.isGutenberg() ) {
				window.acf.addAction(
					'render_block_preview/type=' + blockName,
					themeUtils.adminInitializeBlock( initBlock, blockSelector )
				);
			}
		} );
	} else {
		$( document ).ready( function() {
			let $blockSections = $( blockSelector );

			if ( $blockSections.length ) {
				$blockSections.each( function() {
					initBlock( $( this ) );
				} );

				return;
			}

			initBlock( $( blockSelector ) );
		} );
	}
};

export default loadBlock;
