const initBlock = async function( $block ) {
	const { gsap } = await import( 'gsap' );
	const { ScrollTrigger } = await import( 'gsap/ScrollTrigger' );
	gsap.registerPlugin( ScrollTrigger );

	const $header = $block;
	const $mobileMenuBtn = $header.find( '.page-header__mobile-menu-btn' );
	const $desktopMenu = $header.find( '.page-header__nav ul.menu' );
	const $mobileMenu = $header.find( '.mobile-menu' );
	const $mobileMenuOverlay = $header.find( '.mobile-menu-overlay' );
	const $mobileMenuBtnClose = $mobileMenu.find( '.mobile-menu__close-btn' );
	const $mobileMenuHasChildren = $mobileMenu.find( '.menu-item-has-children' );
	const $headerDropdown = $header.find( '.w24-portal' );
	const $siteBlocks = '.wp-site-blocks';

	if ( document.querySelector( $siteBlocks ) ) {
		ScrollTrigger.create( {
			scroller: $siteBlocks,
			start: 'top+=80',
			end: '+=50000',
			toggleClass: { targets: '.page-header', className: 'page-header--fixed' },
			markers: false,
			invalidateOnRefresh: true
		} );
	}

	const menuOpen = () => {
		$mobileMenuBtn.addClass( 'is-active' );
		$mobileMenu.addClass( 'is-active' );
		$mobileMenuOverlay.fadeIn( 400 ).addClass( 'is-active' );
	};

	const menuClose = () => {
		$mobileMenuBtn.removeClass( 'is-active' );
		$mobileMenu.removeClass( 'is-active' );
		$mobileMenuOverlay.fadeOut( 300 ).removeClass( 'is-active' );
	};

	$mobileMenuBtn.on( 'click', function( e ) {
		e.preventDefault();
		const $this = $( this );
		if ( $this.hasClass( 'is-active' ) ) {
			menuClose();
		} else {
			menuOpen();
		}
	} );

	$mobileMenuOverlay.on( 'click', function( e ) {
		e.preventDefault();
		menuClose();
	} );

	$mobileMenuBtnClose.on( 'click', function( e ) {
		e.preventDefault();
		menuClose();
	} );

	$mobileMenuHasChildren.children( 'a' ).children( '.menu-item-icon' ).on( 'click', function( e ) {
		e.preventDefault();
		const $thisItem = $( this ).closest( 'li' );
		const $submenu = $thisItem.find( '.sub-menu' );
		$thisItem.toggleClass( 'is-submenu-active' );
		$submenu.slideToggle();
	} );

	$desktopMenu.find( '.menu-item-icon' ).on( 'click', function( e ) {
		if ( $( window ).width() > 1279 ) {
			e.preventDefault();
		}
	} );

	$headerDropdown.on( 'click', function( e ) {
		const $this = $( this );
		const $target = $( e.target );
		if ( !$target.closest( '.w24-portal__dropdown' ).length ) {
			e.preventDefault();
			$this.toggleClass( 'is-hover' );
		}
	} );


};

themeUtils.loadBlock( initBlock, 'header', '.page-header' );
